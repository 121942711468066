import React, { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import Contact from './Contact';

const Support = () => {
  const faqRef = useRef();
  const contactRef = useRef();

  useGSAP(() => {
    gsap.from('#faq', {
      scrollTrigger: {
        trigger: '#faq',
        toggleActions: 'play pause resume reverse',
        start: 'top 80%',
      },
      opacity: 0,
      y: 50,
      duration: 1,
    });

    gsap.from('#contactForm', {
      scrollTrigger: {
        trigger: '#contactForm',
        toggleActions: 'play pause resume reverse',
        start: 'top 80%',
      },
      opacity: 0,
      y: 50,
      duration: 1,
    });
  }, []);

  return (
    <section className="h-full common-padding bg-light relative overflow-hidden">
      <div>
        {/* FAQs Section */}
        <div id="faq" className="faq-section" ref={faqRef}>
          <h2 className="text-4xl mb-8 text-center">Frequently Asked Questions</h2>
          <div className="faq-list">
            <div className="faq-item">
              <h3 className="text-2xl font-semibold">How can I install LumiaBright?</h3>
              <p className="text-lg">You can easily install LumiaBright by following the instruction manual included with the product, or you can contact our installation support team for assistance.</p>
            </div>

            <div className="faq-item">
              <h3 className="text-2xl font-semibold">What is the warranty period?</h3>
              <p className="text-lg">All LumiaBright products come with a 1-year warranty covering any manufacturing defects.</p>
            </div>

            <div className="faq-item">
              <h3 className="text-2xl font-semibold">How do I return a faulty product?</h3>
              <p className="text-lg">You can return faulty products by contacting our customer support within 30 days of purchase for an exchange.</p>
            </div>

            <div className="faq-item">
              <h3 className="text-2xl font-semibold">Can I use LumiaBright in outdoor settings?</h3>
              <p className="text-lg">LumiaBright is designed for indoor use. For outdoor lighting solutions, please check out our other products.</p>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <Contact />
      </div>
    </section>
  );
};

export default Support;
