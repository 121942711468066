import React, { useRef, useEffect, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { a } from '@react-spring/three';

export function Spot({
  isRotating,
  setIsRotating,
  setCurrentStage,
  ...props
}) {
  const spotRef = useRef();
  const { gl, viewport } = useThree();
  const { nodes, materials } = useGLTF('/assets/Spot.glb');

  const lastPosition = useRef({ x: 0, y: 0 });
  const rotationSpeed = useRef({ x: 0, y: 0 });
  const dampingFactor = 0.95;

  const handlePointerDown = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsRotating(true);
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    lastPosition.current = { x: clientX, y: clientY };
  }, [setIsRotating]);

  const handlePointerUp = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsRotating(false);
  }, [setIsRotating]);

  const handlePointerMove = useCallback((event) => {
    if (!isRotating) return;
    event.stopPropagation();
    event.preventDefault();
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const deltaX = (clientX - lastPosition.current.x) / viewport.width;
    const deltaY = (clientY - lastPosition.current.y) / viewport.height;
    spotRef.current.rotation.y += deltaX * 0.01 * Math.PI;
    spotRef.current.rotation.x += deltaY * 0.01 * Math.PI;
    lastPosition.current = { x: clientX, y: clientY };
    rotationSpeed.current = { x: deltaY * 0.01 * Math.PI, y: deltaX * 0.01 * Math.PI };
  }, [isRotating, viewport.width, viewport.height]);

  useEffect(() => {
    const canvas = gl.domElement;
    canvas.addEventListener("pointerdown", handlePointerDown);
    canvas.addEventListener("pointerup", handlePointerUp);
    canvas.addEventListener("pointermove", handlePointerMove);
    return () => {
      canvas.removeEventListener("pointerdown", handlePointerDown);
      canvas.removeEventListener("pointerup", handlePointerUp);
      canvas.removeEventListener("pointermove", handlePointerMove);
    };
  }, [gl, handlePointerDown, handlePointerUp, handlePointerMove]);

  useFrame(() => {
    if (!isRotating) {
      rotationSpeed.current.x *= dampingFactor;
      rotationSpeed.current.y *= dampingFactor;
      if (Math.abs(rotationSpeed.current.x) < 0.001) rotationSpeed.current.x = 0;
      if (Math.abs(rotationSpeed.current.y) < 0.001) rotationSpeed.current.y = 0;
      spotRef.current.rotation.x += rotationSpeed.current.x;
      spotRef.current.rotation.y += rotationSpeed.current.y;
    } else {
      const rotation = spotRef.current.rotation;
      const normalizedRotationY = ((rotation.y % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
      // Adjust these values based on your specific spot model and desired stages
      if (normalizedRotationY >= 5.45 && normalizedRotationY <= 5.85) setCurrentStage(4);
      else if (normalizedRotationY >= 0.85 && normalizedRotationY <= 1.3) setCurrentStage(3);
      else if (normalizedRotationY >= 2.4 && normalizedRotationY <= 2.6) setCurrentStage(2);
      else if (normalizedRotationY >= 4.25 && normalizedRotationY <= 4.75) setCurrentStage(1);
      else setCurrentStage(null);
    }
  });

  return (
    <a.group ref={spotRef} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, Math.PI]} scale={0.25}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Head.geometry}
          material={materials.PaletteMaterial001}
          position={[0.005, 0, 0.025]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Tail.geometry}
          material={materials.PaletteMaterial002}
          position={[0.005, 0, 0.025]}
        />
      </group>
    </a.group>
  );
}

useGLTF.preload('/assets/Spot.glb');

export default Spot;
