import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { voltfixImg, bagImg, searchImg } from '../utils';
import { useCart, CartProvider } from '../context/CartContext';

const navLists = [
  { name: "LumiaVolt", path: "/" },
  { name: "LumiaTube", path: "/lumiatube" },
  { name: "LumiaBright", path: "/lumiabright" },
  { name: "Support", path: "/support" }
];

const NavbarContent = () => {
  const [showCart, setShowCart] = useState(false);
  const { cart, removeFromCart } = useCart();

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  return (
    <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center">
      <nav className="flex w-full screen-max-width">
        <Link to="/">
          <img src={voltfixImg} alt="Voltfix" width={50} height={50} />
        </Link>

        <div className="flex flex-1 justify-center max-sm:hidden">
          {navLists.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="px-5 text-sm cursor-pointer text-gray hover:text-white transition-all"
            >
              {nav.name}
            </Link>
          ))}
        </div>

        <div className="flex items-baseline gap-7 max-sm:justify-end max-sm:flex-1">
          <img src={searchImg} alt="search" width={18} height={18} />
          <div className="relative">
            <img src={bagImg} alt="bag" width={18} height={18} onClick={toggleCart} className="cursor-pointer" />
            {cart && cart.length > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {cart.length}
              </span>
            )}
          </div>
        </div>
      </nav>

      {showCart && (
        <div className="absolute top-16 right-0 bg-white p-4 shadow-lg rounded-lg">
          <h3 className="text-lg font-bold mb-2">Cart</h3>
          {cart.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <ul>
              {cart.map((item, index) => (
                <li key={index} className="flex justify-between items-center mb-2">
                  <span>{item.name} - ₹{item.price}</span>
                  <button onClick={() => removeFromCart(item.id)} className="text-red-500">Remove</button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </header>
  );
};

const Navbar = () => (
  <CartProvider>
    <NavbarContent />
  </CartProvider>
);

export default Navbar;