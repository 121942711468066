






import exploremv from "/assets/videos/explore.mp4";


import voltfix from "/assets/images/voltfix.png"
import search from "/assets/images/search.svg";
import bag from "/assets/images/bag.svg";

import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";


import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.png";






export const exploreVideo = exploremv;


export const voltfixImg = voltfix;
export const searchImg = search;
export const bagImg = bag;

export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;


export const explore1Img = explore1;
export const explore2Img = explore2;
