import React, { Suspense, useCallback, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import Tube from './models/Tube';
import TubeFeatures from './TubeFeatures';
import { useCart } from '../context/CartContext';
import { sendOrderConfirmation } from '../services/emailService';

const Scene = React.memo(() => {
  const [isRotating, setIsRotating] = useState(false);
  const [currentStage, setCurrentStage] = useState(null);

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Tube
        isRotating={isRotating}
        setIsRotating={setIsRotating}
        setCurrentStage={setCurrentStage}
      />
      <OrbitControls enableZoom={false} />
    </>
  );
});

const CTA = React.memo(() => {
  const { addToCart } = useCart();
  const [showNotification, setShowNotification] = useState(false);

  useGSAP(() => {
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 })
  }, []);

  const handleAddToCart = () => {
    addToCart({ id: 'lumiatube', name: 'LumiaTube', price: 99.99 });
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handleBuyNow = async () => {
    addToCart({ id: 'lumiatube', name: 'LumiaTube', price: 99.99 });
    await sendOrderConfirmation('LumiaTube');
    alert('Order placed successfully!');
  };

  return (
    <div id="cta" className="flex flex-col items-center opacity-0 translate-y-20 my-24">
      <button onClick={handleAddToCart} className="btn mb-4">Add to Cart</button>
      <button onClick={handleBuyNow} className="btn">Buy Now</button>
      <p className="font-normal text-xl mt-4">From ₹ 99.99</p>
      {showNotification && (
        <div className="notification">Added to cart!</div>
      )}
    </div>
  );
});

const LumiaTube = () => {
  const handleCreated = useCallback(({ gl }) => {
    gl.setClearColor(new THREE.Color('#000000'), 1);
  }, []);

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 })
  }, []);

  return (
    <main className="bg-black">
      <div style={{ height: '100vh', width: '100vw' }}>
        <Canvas camera={{ position: [0, 0, 5], fov: 75 }} onCreated={handleCreated}>
          <Suspense fallback={null}>
            <Scene />
          </Suspense>
        </Canvas>
      </div>
      <div>
        <p id="hero" className="hero-title opacity-0">LumiaTube</p>
      </div>
      <section className="w-full nav-height bg-black relative my-24">
        <CTA />
      </section>
      <TubeFeatures />
    </main>
  );
};

export default React.memo(LumiaTube);
