
// Bulb sizes
export const bulbSizes = [
  { label: 'Standard', value: 'standard' },
  { label: 'Large', value: 'large' },
];

// Bulb models
export const bulbModels = [
  {
    title: 'Standard LED Bulb',
    color: ['#FFFFFF', '#F1F1F1', '#E0E0E0'],
    img: '/images/standard-led-bulb.png',
  },
  {
    title: 'Warm White LED Bulb',
    color: ['#FFF4E5', '#FFE0B2', '#FFD180'],
    img: '/images/warm-white-led-bulb.png',
  },
  {
    title: 'Cool White LED Bulb',
    color: ['#F1F8FF', '#E3F2FD', '#BBDEFB'],
    img: '/images/cool-white-led-bulb.png',
  },
  {
    title: 'RGB Smart LED Bulb',
    color: ['#FF0000', '#00FF00', '#0000FF'],
    img: '/images/rgb-smart-led-bulb.png',
  },
];

export const navLists = ["LumiaVolt", "LumiaTube", "LumiaBright", "Support"];



export const footerLinks = [
  "Privacy Policy",
  "Terms of Use",
  "Sales Policy",
  "Legal",
  "Site Map",
];