import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import Navbar from './components/Navbar';
import Hero from '../src/components/Hero';
import LumiaTube from '../src/components/LumiaTube';
import LumiaBright from '../src/components/LumiaBright';
import Support from '../src/components/Support';
import Footer from './components/Footer';

function App() {
  return (
    <CartProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/lumiatube" element={<LumiaTube />} />
          <Route path="/lumiabright" element={<LumiaBright />} />
          <Route path="/support" element={<Support />} />
        </Routes>
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;