import emailjs from '@emailjs/browser';

export const sendOrderConfirmation = async (productName) => {
  try {
    await emailjs.send(
      'YOUR_SERVICE_ID',
      'YOUR_TEMPLATE_ID',
      { product_name: productName },
      'YOUR_USER_ID'
    );
    console.log('Order confirmation email sent successfully');
  } catch (error) {
    console.error('Failed to send order confirmation email:', error);
  }
};