import React, { useState, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGSAP } from '@gsap/react';
import * as THREE from 'three';
import gsap from 'gsap';
import Model from './Model';
import Features from './Features';
import Bulb from './models/Bulb';

const CTA = React.memo(() => (
  <div id="cta" className="flex flex-col items-center opacity-0 translate-y-20 my-24">
    <a href="#highlights" className="btn">Buy</a>
    <p className="font-normal text-xl">From ₹ 99.99</p>
  </div>
));

const Scene = React.memo(() => {
  const [isRotating, setIsRotating] = useState(false);
  const [currentStage, setCurrentStage] = useState(null);

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Bulb
        isRotating={isRotating}
        setIsRotating={setIsRotating}
        setCurrentStage={setCurrentStage}
      />
    </>
  );
});

const Hero = () => {
  const handleCreated = useCallback(({ gl }) => {
    gl.setClearColor(new THREE.Color('#000000'), 1);
  }, []);

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 })
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 })
  }, []);

  return (
    <main className="bg-black">
      
      <div style={{ height: '100vh', width: '100vw' }}>
        <Canvas camera={{ position: [0, 0, 5], fov: 75 }} onCreated={handleCreated}>
          <Scene />
        </Canvas>
      </div>
      <section className="w-full nav-height bg-black relative my-24">
        <CTA />
      </section>
      <Features />
    </main>
  );
};

export default React.memo(Hero);